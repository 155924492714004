import React from "react";
import "./Panel.css";
import { Link } from "react-router-dom";

import {
  faGlobe,
  faUsers,
  faStore,
  faSquareEnvelope,
  faClockRotateLeft,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Panel = () => {
  return (
    <div className="content-container">
      <div>
        <div className="dashboard"></div>
        <div className="rectangles-container">
          <div className="rectangle-flex-container administrar-tienda">
            <div className="rectangle tienda">
              <div className="faIcon">
                <Link className="faIcon" to="/">
                  <FontAwesomeIcon className="tienda" icon={faGlobe} />{" "}
                </Link>
              </div>
              <div className="icon-title">tienda online</div>
            </div>
            <div className="rectangle tienda">
              <div className="faIcon">
                <Link className="faIcon" to="/mis-pedidos">
                  <FontAwesomeIcon icon={faClockRotateLeft} />{" "}
                </Link>
              </div>
              <div className="icon-title" style={{}}>
                Mis compras
              </div>
            </div>
          </div>
          <div className="rectangle-flex-container">
            <div className="rectangle pedidos">
              <div className="faIcon">
                <Link className="faIcon" to="/registro-de-pedidos">
                  <FontAwesomeIcon
                    className="pedidos"
                    icon={faSquareEnvelope}
                  />{" "}
                </Link>
              </div>
              <div className="icon-title">Nuevos Pedidos</div>
            </div>
            <div className="rectangle administrar">
              <div className="faIcon">
                <Link className="faIcon" to="/administrar-productos">
                  <FontAwesomeIcon className="administrar" icon={faStore} />{" "}
                </Link>
              </div>
              <div className="icon-title">Administra tu tienda</div>
            </div>
          </div>

          <div className="rectangle-flex-container clientes-pedidos">
            <div className="rectangle mis-pedidos">
              <div className="faIcon">
                <Link className="faIcon" to="/codigos-descuento">
                  <FontAwesomeIcon className="tienda" icon={faPercent} />{" "}
                </Link>
              </div>
              <div className="icon-title">Códigos descuento</div>
            </div>
            <div className="rectangle clientes">
              <div className="faIcon">
                <Link className="faIcon" to="/administrar-usuarios">
                  <FontAwesomeIcon className="mis-pedidos" icon={faUsers} />{" "}
                </Link>
              </div>
              <div className="icon-title">Usuarios</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
