import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Menu from "./pages/Home/Menu/Menu";

import SignUpForm from "./components/User/SignUpForm";
import SignInForm from "./components/User/SignInForm";
import Productos from "./pages/Admin/Productos/Productos";
import Usuarios from "./pages/Admin/Users/Usuarios";
import MisPedidos from "./pages/Pedidos/MisPedidos";
import CheckoutCart from "./pages/Carrito/CheckoutCart";
import CheckoutShipping from "./pages/Carrito/CheckoutShipping";
import CheckoutPayment from "./pages/Carrito/CheckoutPayment";
import PedidoConfirmado from "./pages/Pedidos/PedidoConfirmado";
import CodeManagement from "./pages/Admin/Descuentos/CodeManagement";
import { AuthProvider } from "./context/AuthContext";
import { ShoppingProvider } from "./context/ShoppingContext";
import { useIsUserAdmin } from "./context/AuthContext";

import Panel from "./pages/Admin/Panel/Panel";
import 'react-whatsapp-widget/dist/index.css';
import EditarDatosPersonales from "./pages/Home/Cuenta/EditarDatosPersonales";

import "./styles.css";
import RegistroPedidos from "./pages/Admin/Pedidos/RegistroPedidos";
import GoogleAnalyticsTracker from "./analytics/GoogleAnalyticsTracker";

function App() {
  const isUserAdmin = useIsUserAdmin();
  const location = useLocation();

  // Comprueba si la ubicación actual coincide con "/login" o "/registrarse"
  const shouldShowFooter = ![
    "/login",
    "/registrarse",
    "/registro-de-pedidos",
    "/administrar-productos",
    "/administrar-usuarios",
    "/checkout/cart",
    "/checkout/payment",
    "/cuenta/datos-personales",
    "/panel",
    "/mis-pedidos",
    "/pedido-confirmado/:pedidoId/:trackId", // Nueva ruta excluida
  ].includes(location.pathname);


  return (
    <div className="app">
      <GoogleAnalyticsTracker trackingID="G-G3C6NY6RC2" />
      <Header />
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/mis-pedidos" element={<MisPedidos />} />

        <Route path="/registrarse" element={<SignUpForm />} />
        <Route path="/login" element={<SignInForm />} />

        {isUserAdmin && (
          <>
            <Route path="/panel" element={<Panel />} />
            <Route path="/administrar-productos" element={<Productos />} />
            <Route path="/administrar-usuarios" element={<Usuarios />} />
            <Route path="/registro-de-pedidos" element={<RegistroPedidos />} />
            <Route path="/codigos-descuento" element={<CodeManagement />} />
          </>
        )}

        <Route path="/menu" element={<Menu />} />

        {/* Example of other possible routes */}
        <Route path="/checkout/cart" element={<CheckoutCart />} />
        <Route path="/checkout/shipping" element={<CheckoutShipping />} />
        <Route path="/checkout/payment" element={<CheckoutPayment />} />

        <Route
          path="/pedido-confirmado/:pedidoId/:trackId"
          element={<PedidoConfirmado />}
        />
        <Route
          path="cuenta/datos-personales"
          element={<EditarDatosPersonales />}
        />
        {/*<Route path="/stripe" element={<StripeContainer />} />
          <Route path="/editAdmin" element={<EditAdmin />} /> */}
      </Routes>
      {shouldShowFooter && <Footer />}
    </div>
  );
}

function MainApp() {
  return (
    <Router>
      <ShoppingProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ShoppingProvider>
    </Router>
  );
}

export default MainApp;
