import React, { useState, useEffect } from "react";
import { useShoppingContext } from "../../../context/ShoppingContext";
import axios from "axios";
import "./RegistroPedidos.css";
import "../../../components/Header/Header.css";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Importa el módulo de autotable para jspdf
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import PedidoCard from "./PedidoCard";

const RegistroPedidos = () => {
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [showPedidosGrupo, setShowPedidosGrupo] = useState({});

  const {
    obtenerUsuarios,
    obtenerPedidos,
    pedidos,
    obtenerProductos,
    productosOriginales,
  } = useShoppingContext();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await obtenerPedidos();
        await obtenerProductos();
        await obtenerUsuarios();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [obtenerPedidos, obtenerProductos, obtenerUsuarios]);

  const formattedTime = currentDateTime.toLocaleTimeString("es-PE", {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  const formattedDate = currentDateTime.toLocaleDateString("es-PE", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });


  const formatDate = (createdAt) => {
    return new Date(createdAt).toLocaleDateString("es-PE", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  const actualizarEstadoPedido = async (pedidoId, nuevoEstado) => {
    try {
      if (
        selectedPedido &&
        typeof selectedPedido === "object" &&
        selectedPedido.id
      ) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`,
          {
            estado_pedido: nuevoEstado,
          }
        );

        const nuevosPedidos = pedidos.map((pedido) => {
          if (pedido.id === pedidoId) {
            return { ...pedido, estado_pedido: nuevoEstado };
          }
          return pedido;
        });

        obtenerPedidos(nuevosPedidos);

        console.log(
          `Estado del pedido ${pedidoId} actualizado a ${nuevoEstado}`
        );
      }
    } catch (error) {
      console.error("Error al actualizar el estado del pedido:", error);
    }
  };

  const handleDeletePedido = async (pedidoId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`
      );
      // Después de eliminar el pedido, actualiza la lista de pedidos
      obtenerPedidos();
    } catch (error) {
      console.error("Error al eliminar el pedido:", error);
    }
  };

  const handleEstadoChange = (e, pedido) => {
    const nuevoEstado = e.target.value;
    setSelectedPedido((prevSelectedPedido) => {
      const updatedSelectedPedido = { ...prevSelectedPedido, ...pedido };
      return updatedSelectedPedido;
    });

    const pedidoId = pedido.id;
    actualizarEstadoPedido(pedidoId, nuevoEstado);
  };

  const sortedPedidos = pedidos.slice().sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  const exportToPdf = () => {
    const doc = new jsPDF();

    const title = "COMBO BLANQUIAZUL - Pedidos Online";

    const columns = [
      { title: "ID Pedido", dataKey: "id" },
      { title: "Nombre", dataKey: "nombre" },
      { title: "Cantidad", dataKey: "cantidad" }, // Agregar columna para detalles
      { title: "Monto total", dataKey: "monto_total" },
      { title: "¿Pagó?", dataKey: "estado_pedido" },
    ];

    // Ordenar primero por estado de pedido y luego alfabéticamente
    const sortedPedidosByEstado = sortedPedidos.sort((a, b) => {
      const order = ["Activo", "Confirmado", "Finalizado"];
      return order.indexOf(a.estado_pedido) - order.indexOf(b.estado_pedido);
    });

    // Calcular el total de todas las ventas
    const totalVentas = sortedPedidosByEstado.reduce(
      (total, pedido) => total + pedido.monto_total,
      0
    );

    // Crear filas para el PDF
    const rows = sortedPedidosByEstado.map((pedido) => ({
      id: pedido.id,
      createdAt: formatDate(pedido.createdAt),
      nombre: pedido.nombre,
      monto_total: pedido.monto_total.toFixed(2),
      cantidad: pedido.monto_total.toFixed(2) / 15,
      estado_pedido: pedido.estado_pedido === "Confirmado" ? "Sí" : "No",
    }));

    doc.text(title, 15, 10); // Agregar el título en la posición (15, 10)

    doc.autoTable(columns, rows, {
      startY: 20,
      margin: { horizontal: 15 },
      styles: { fontSize: 10, cellPadding: 2 },
      columnStyles: { id: { cellWidth: 20 } },
      theme: "grid",
    });

    // Agregar totales fuera de la tabla
    doc.setFont("helvetica", "bold");

    doc.text(
      `Total ventas online: S/. ${totalVentas.toFixed(2)}`,
      15,
      doc.lastAutoTable.finalY + 40
    );

    doc.save("lista-ventas-online.pdf");
  };

  const obtenerDetalles = async (pedidoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}/detalles`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error al obtener los detalles del pedido ${pedidoId}:`,
        error
      );
    }
  };

  // Agrupar los pedidos por estado
  const groupedPedidos = sortedPedidos.reduce((acc, pedido) => {
    const estado = pedido.estado_pedido;
    if (!acc[estado]) {
      acc[estado] = [];
    }
    acc[estado].push(pedido);
    return acc;
  }, {});

  const togglePedidosGrupo = (estado) => {
    setShowPedidosGrupo((prevState) => ({
      ...prevState,
      [estado]: !prevState[estado],
    }));
  };

  return (
    <div className="content-container pedidos-container">
      <h2 className="registro-pedidos-heading">Registro de Pedidos </h2>
      <div className="clock flex-space-around">
        <div>
          <FontAwesomeIcon icon={faClock} /> <span>{formattedTime}</span>{" "}
        </div>

        <div>
          <FontAwesomeIcon icon={faCalendar} /> <span>{formattedDate}</span>{" "}
        </div>

        <button onClick={exportToPdf}>
          Lista digital{" "}
          <span className="pdf">
            <FontAwesomeIcon icon={faFilePdf} />
          </span>
        </button>
      </div>
      {["Activo", "Confirmado", "Finalizado"].map((estado) => (
        <div key={estado}>
          {groupedPedidos[estado] && (
            <h3
              onClick={() => togglePedidosGrupo(estado)}
              className={
                estado === "Activo"
                  ? "pedidos-ordenados bg-green"
                  : estado === "Confirmado"
                  ? "pedidos-ordenados bg-yellow"
                  : estado === "Finalizado"
                  ? "pedidos-ordenados  bg-gray"
                  : "pedidos-ordenados"
              }
            >
              <span>{showPedidosGrupo[estado] ? "▼" : "▶"}</span>{" "}
              {/* Icono para mostrar/ocultar */}
              {estado === "Activo"
                ? "Pedidos nuevos"
                : estado === "Confirmado"
                ? "Confirmados"
                : estado === "Finalizado"
                ? "Entregado"
                : estado}
            </h3>
          )}
          {showPedidosGrupo[estado] && groupedPedidos[estado] && (
            <>
              {groupedPedidos[estado].map((pedido) => (
                <PedidoCard
                  key={pedido.id}
                  pedido={pedido}
                  handleEstadoChange={handleEstadoChange}
                  handleDeletePedido={handleDeletePedido}
                  productosOriginales={productosOriginales}
                  obtenerDetalles={obtenerDetalles}
                />
              ))}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default RegistroPedidos;
