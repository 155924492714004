import React, { useState, useEffect, useCallback } from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState({}); // Inicializa timeLeft como un objeto vacío

  const calculateTimeLeft = useCallback(() => {
    const targetDate = new Date("2024-05-28T14:00:00"); // Mueve la definición de targetDate dentro de useCallback
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      const totalSeconds = Math.floor(difference / 1000);
      timeLeft = {
        days: Math.floor(totalSeconds / (24 * 60 * 60)),
        hours: Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60)),
        minutes: Math.floor((totalSeconds % (60 * 60)) / 60),
        seconds: totalSeconds % 60,
      };
    }

    return timeLeft;
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft()); // Invoca calculateTimeLeft como función para obtener el tiempo restante actualizado
    }, 1000);

    return () => clearTimeout(timer);
  }, [calculateTimeLeft]);

  const formatNumber = (number) => {
    // Verifica si el número es indefinido o nulo
    if (number == null) {
      return "";
    }
    // Verifica si el número es menor que 10 y lo formatea con un cero delante si es así
    return number < 10 ? `0${number}` : number.toString();
  };

  return (
    <>
      <h2 className="oferta">
        <FontAwesomeIcon style={{ color: "#000000aa" }} icon={faClock} /> ¡Pide
        online y evita las colas!
      </h2>
      <div className="time">
        <p>
          <span>{formatNumber(timeLeft.days)}</span>
          <span>días, </span> <span>{formatNumber(timeLeft.hours)}</span>
          <span>h</span>
          <span>{formatNumber(timeLeft.minutes)}</span>
          <span>m</span>
          <span>{formatNumber(timeLeft.seconds)}</span>
          <span>s</span>
        </p>
      </div>
      <h2 className="oferta">SOLO PARA TRIBUNA SVR</h2>
    </>
  );
};

export default Timer;
