import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useShoppingContext } from "../../../context/ShoppingContext";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsUserAdmin } from "../../../context/AuthContext";

const PedidoCard = ({ pedido, handleEstadoChange, handleDeletePedido }) => {
  const { productos, obtenerProductos, obtenerPedidos } = useShoppingContext();
  const isUserAdmin = useIsUserAdmin();
  const [detallesPedido, setDetallesPedido] = useState([]);
  const [mostrarDetalles, setMostrarDetalles] = useState(false);

  useEffect(() => {
    const fetchDetallesPedido = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pedidos/${pedido.id}/detalles`
        );
        const { detalles } = response.data;
        setDetallesPedido(detalles);
      } catch (error) {
        console.error("Error al obtener detalles del pedido:", error);
      }
    };

    fetchDetallesPedido();
  }, [pedido.id]);

  useEffect(() => {
    obtenerProductos();
  }, [obtenerProductos]);

  const formatDate = (createdAt) => {
    return new Date(createdAt).toLocaleDateString("es-PE", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  const formatTime = (createdAt) => {
    return new Date(createdAt).toLocaleTimeString("es-PE", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleEstadoDetallesChange = async (e, detalle) => {
    try {
      const nuevoEstado = e.target.value;
      // Realiza una solicitud PUT a la API para actualizar el estado del detalle
      await axios.put(
        `${process.env.REACT_APP_API_URL}/pedidos/${detalle.id}/detalle`,
        { estado: nuevoEstado }
      );

      // Actualiza el estado localmente para reflejar el cambio
      setDetallesPedido((prevDetallesPedido) =>
        prevDetallesPedido.map((det) =>
          det.id === detalle.id ? { ...det, estado: nuevoEstado } : det
        )
      );
    } catch (error) {
      console.error(
        "Error al cambiar el estado del detalle del pedido:",
        error
      );
    }
  };

  const handleConfirmar = async (pedidoId) => {
    try {
      // Realizar una solicitud PUT a la API para confirmar el pedido
      await axios.put(`${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`, {
        estado_pedido: "Confirmado",
      });
      // Llama a la función para obtener los pedidos actualizados
      obtenerPedidos();

      console.log("Pedido confirmado exitosamente");
    } catch (error) {
      console.error("Error al confirmar el pedido:", error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  const handleEntregado = async (pedidoId) => {
    try {
      // Realizar una solicitud PUT a la API para confirmar el pedido
      await axios.put(`${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`, {
        estado_pedido: "Finalizado",
      });

      // Llama a la función para obtener los pedidos actualizados
      obtenerPedidos();
      console.log("Pedido entregado exitosamente");
    } catch (error) {
      console.error("Error al entregar el pedido:", error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  const calcularPrecioTotal = (detalle) => {
    let precioTotal = detalle.precio_unitario * detalle.cantidad;

    return precioTotal;
  };

  const handleMostrarDetalles = () => {
    setMostrarDetalles(!mostrarDetalles);
  };

  return (
    <div className={`pedido-card`}>
      <div className="flex-space-between">
        <h4>PEDIDO N°{pedido.id}</h4>
        {pedido.estado_pedido === "Activo" ? (
          <button
            className="confirmar-btn"
            onClick={(e) => {
              e.stopPropagation();
              handleConfirmar(pedido.id);
            }}
          >
            Confirmar Pedido
          </button>
        ) : pedido.estado_pedido === "Confirmado" ? (
          <button
            className="confirmar-btn bg-red"
            onClick={(e) => {
              e.stopPropagation();
              handleEntregado(pedido.id);
            }}
          >
            Entregar Pedido
          </button>
        ) : pedido.estado_pedido === "Confirmado" ? (
          <button
            className="confirmar-btn bg-gray"
            onClick={(e) => {
              e.stopPropagation();
              handleEntregado(pedido.id);
            }}
          >
            Entregado
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="pedido-card-header">
        <h4>{pedido.nombre}</h4>
        <span>
          {pedido.direccion !== "Recojo en tienda" ? (
            <span>DIRECCIÓN: {pedido.direccion}</span>
          ) : (
            "Recojo en local"
          )}
        </span>
        <span>{formatDate(pedido.createdAt)} </span>

        <p>
          <span>{formatTime(pedido.createdAt)}</span>{" "}
        </p>
      </div>

      <div className="details">
        <div className="details-header">
          <Link
            to={`/pedido-confirmado/${pedido.id}/${pedido.track_id}`}
            target="_blank"
            className="details-link"
          >
            TRACKING LINK
          </Link>
          <div className="flex-start" onClick={handleMostrarDetalles}>
            <h3>Ver detalles del pedido</h3>
            <FontAwesomeIcon
              icon={mostrarDetalles ? faAngleUp : faAngleDown}
              className={`toggle-details-icon`}
            />
          </div>
        </div>
        {mostrarDetalles && (
          <div className="details-body">
            <div className="pedido-card-footer">
              {isUserAdmin ? (
                <div>
                  <button
                    className="delete-pedido-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePedido(pedido.id);
                    }}
                  >
                    Eliminar Pedido
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="estado-select-container">
                <select
                  className="estado-select"
                  value={pedido.estado_pedido}
                  onChange={(e) => handleEstadoChange(e, pedido)}
                >
                  <option value="Activo">nuevo</option>
                  <option value="Confirmado">Confirmado</option>
                  <option value="Finalizado">Entregado</option>
                </select>
              </div>
            </div>

            <table className="detalles-pedido-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Precio Unitario</th>
                  <th>Precio Total</th>
                  <th>estado</th>
                </tr>
              </thead>
              <tbody>
                {detallesPedido &&
                  detallesPedido.map((detalle, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {
                          productos.find(
                            (producto) =>
                              producto.producto_id === detalle.producto_id
                          )?.nombre
                        }
                      </td>

                      <td>{detalle.cantidad}</td>
                      <td>{detalle.precio_unitario}</td>
                      <td>{calcularPrecioTotal(detalle)}</td>
                      <td>
                        <select
                          value={detalle.estado}
                          onChange={(e) =>
                            handleEstadoDetallesChange(e, detalle)
                          }
                        >
                          <option value="preparing">Preparando</option>
                          <option value="packing">Empacando</option>
                          <option value="ready">Listo</option>
                        </select>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="instrucciones-area">
              <h3>Instrucciones del cliente</h3>
              <textarea
                value={pedido.instrucciones ? pedido.instrucciones : "Ninguna"}
                readOnly
              />
            </div>
            <div className="pedido-card-body">
              <ul className="details-list">
                <li className={`details-item`}>TELÉFONO: {pedido.telefono}</li>
                <li className={`details-item`}>CORREO: {pedido.email}</li>

                <li className={`details-item`}>
                  MÉTODO DE PAGO: {pedido.metodo_pago}
                </li>

                {pedido.descuento ? (
                  <li className={`details-item`}>
                    CÓDIGO DE DESCUENTO UTILIZADO: {pedido.codigo}
                  </li>
                ) : (
                  ""
                )}

                <li className={`details-item`}>
                  TIPO DE COMPROBANTE:{" "}
                  {pedido.dni
                    ? "Boleta electrónica"
                    : `${pedido.comprobante} Simple`}
                </li>
                {pedido.dni ? (
                  <li className={`details-item`}>DNI: {pedido.dni}</li>
                ) : (
                  ""
                )}
                {pedido.comprobante === "Factura" && (
                  <>
                    <li className={`details-item`}>
                      RAZÓN SOCIAL: {pedido.razon_social}
                    </li>
                    <li className={`details-item`}>RUC: {pedido.ruc}</li>
                  </>
                )}
              </ul>
              <div className="pedido-card-totales">
                {pedido.subtotal !== pedido.total ? (
                  <h3 className={`details-item`}>
                    SUBTOTAL: S/. {pedido.subtotal}
                  </h3>
                ) : (
                  ""
                )}
                <h3>Total: S/. {pedido.total}</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PedidoCard;
