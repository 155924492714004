import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhatsAppButton from "../WhatsappButton/WhatsappButton";
import {
  faHistory,
  faList,
  faUsers,
  faStore,
  faGlobe,
  faBars,
  faHouse,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import { useAuth, useIsUserAdmin } from "../../context/AuthContext";
import Carrito from "../../pages/Carrito/Carrito";
import { useShoppingContext } from "../../context/ShoppingContext";
import SignUpForm from "../User/SignUpForm";
import SignInForm from "../User/SignInForm";
import UserMenu from "./UserMenu/UserMenu";

const Header = () => {
  const { authenticatedUser } = useAuth();
  const isUserAdmin = useIsUserAdmin();

  const {
    carrito,
    eliminarDelCarrito,
    modificarCantidad,
    setProductos,
    toggleCart,
    closeCart,
    cartOpen,
    userMenuOpen,
    toggleUserMenu,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
    showPopUp,
    setShowPopUp,
    toggleSidebar,
    isSidebarOpen,
    setCartItemCount,
  } = useShoppingContext();


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: {
          activo: true,
        },
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
        } else {
          console.error("No hay productos agregados.");
        }
      })
      .catch((error) => {
        console.error("Error al obtener productos activos:", error);
      });
  }, [setProductos]);

  useEffect(() => {
    const totalItems = carrito.reduce(
      (total, item) => total + item.cantidad,
      0
    );
    setCartItemCount(totalItems);
  }, [carrito, setCartItemCount]);



  return (
    <>
      {
        <header className="header-container">
          <ul className="header-ul">
            <li>
              <button
                className="sidebar-toggle"
                onClick={() => {
                  if (cartOpen) {
                    toggleCart();
                  }
                  if (userMenuOpen) {
                    toggleUserMenu();
                  }
                  if (showPopUp) {
                    setShowPopUp();
                  }
                  toggleSidebar();
                }}
              >
                <FontAwesomeIcon style={{ color: "#fff" }} icon={faBars} />
              </button>
            </li>

            <li>
              <Link
                className="links-principales"
                to="/"
                onClick={() => {
                  if (cartOpen) {
                    toggleCart();
                  }
                  if (userMenuOpen) {
                    toggleUserMenu();
                  }
                }}
              >
                <img
                  className={`banner-img`}
                  src="/images/logos/icon.webp"
                  alt="Logo"
                  id="logo"
                />
              </Link>
            </li>
            <li
              style={{ display: "flex" }}
              onClick={() => {
                toggleUserMenu();
                if (isSidebarOpen) {
                  toggleSidebar();
                }
              }}
            >
              {authenticatedUser && (
                <span className="userName">{authenticatedUser.nombre}</span>
              )}
              {!authenticatedUser && (
                <button
                  className={`header-btn`}
                  onClick={() => {
                    if (cartOpen) {
                      toggleCart();
                    }

                    if (showLoginForm) {
                      setShowLoginForm(false);
                    }

                    if (!showSignUpForm) {
                      setShowSignUpForm(true);
                    } else {
                      setShowSignUpForm(false);
                    }
                  }}
                >
                  Registrarse
                </button>
              )}
              <div className="user-circle">
                <div className="circle-container">
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                </div>
              </div>
            </li>
          </ul>

          <div className={`sidebar-content ${isSidebarOpen ? "open" : ""}`}>
            {/* Contenido de la barra lateral */}
            <ul>
              <li>
                <Link
                  className="links-principales"
                  to="/"
                  onClick={toggleSidebar}
                >
                  <FontAwesomeIcon style={{ color: "#fff" }} icon={faGlobe} />{" "}
                  Tienda Online
                </Link>
              </li>

              <li>
                <Link
                  to={authenticatedUser ? "/mis-pedidos" : "/"}
                  className="links-principales"
                  onClick={() => {
                    if (cartOpen) {
                      toggleCart();
                    }

                    if (!showLoginForm) {
                      setShowLoginForm(true);
                    } else {
                      setShowLoginForm(false);
                    }

                    if (showSignUpForm) {
                      setShowSignUpForm(false);
                    }
                    if (showPopUp) {
                      setShowPopUp();
                    }
                    if (isSidebarOpen) {
                      toggleSidebar();
                    }
                  }}
                >
                  <FontAwesomeIcon style={{ color: "#fff" }} icon={faHistory} />{" "}
                  Historial de compras
                </Link>
              </li>

              <WhatsAppButton></WhatsAppButton>

              {isUserAdmin && (
                <>
                  <li>
                    <Link
                      className="links-principales"
                      to="/registro-de-pedidos"
                      onClick={toggleSidebar}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faList}
                      />{" "}
                      Pedidos de Clientes
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/administrar-productos"
                      onClick={() => {
                        if (cartOpen) {
                          toggleCart();
                        }
                        if (userMenuOpen) {
                          toggleUserMenu();
                        }
                        if (isSidebarOpen) {
                          toggleSidebar();
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faStore}
                      />{" "}
                      Administrar tienda
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/administrar-usuarios"
                      onClick={() => {
                        toggleSidebar();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faUsers}
                      />{" "}
                      Clientes
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/panel"
                      onClick={toggleSidebar}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faHouse}
                      />{" "}
                      Panel
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <div
              className="toggleSidebar"
              onClick={() => {
                toggleSidebar();
              }}
            ></div>
          </div>

          {userMenuOpen && <UserMenu />}

          {cartOpen && (
            <Carrito
              carrito={carrito}
              eliminarDelCarrito={eliminarDelCarrito}
              modificarCantidad={modificarCantidad}
              cartOpen={cartOpen}
              toggleCart={toggleCart}
            />
          )}

          {cartOpen && <div className="overlay" onClick={closeCart}></div>}
        </header>
      }

      {!authenticatedUser && showLoginForm && <SignInForm />}
      {!authenticatedUser && showSignUpForm && <SignUpForm />}
    </>
  );
};

export default Header;
