import React from "react";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <img
        className="torneo"
        src="/images/logos/liga-1.webp"
        width={70}
        alt="liga 1"
      ></img>
      <div>
        <p className="fecha">Domingo, 19/05 desde las 8 pm</p>
      </div>
      <div className="images">
        <div className="alianza-vs">
        <img height={70} src={`/images/logos/alianza.webp`} alt={`Alianza`} />
        <span>vs.</span>
        </div>
        
        <div className="rival">
          Garcilaso
          <img
            width={70}
            src={`/images/logos/garcilaso.png`}
            alt={`Rival de alianza`}
          />
        </div>
      </div>
      <div>
        <p className="jornada">Apertura - Jornada 16 de 17</p>
      </div>
    </div>
  );
}

export default Banner;
