import React, { useState } from 'react';
import axios from 'axios';
import { useShoppingContext } from '../../../context/ShoppingContext';
import CategoryTable from './categoryTable'; // Importar el componente CategoryTable

const AddCategoryForm = () => {
  const [nombre, setNombre] = useState('');
  const [error, setError] = useState(null);
  const { cargarCategorias } = useShoppingContext(); // Asumiendo que tienes un estado de categorías y una función para cargarlas

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/categorias`, { nombre });
      // Limpiar el campo de nombre después de crear la categoría
      setNombre('');
      // Actualizar el estado de las categorías cargando nuevamente la lista
      cargarCategorias();
    } catch (error) {
      // Manejar errores de solicitud, por ejemplo, mostrar un mensaje de error al usuario
      setError(error.response.data.error);
    }
  };

  return (
    <div>
      <h2>Agregar Nueva Categoría</h2>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nombre:</label>
          <input
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <button type="submit">Agregar Categoría</button>
      </form>
      <CategoryTable /> {/* Renderizar la tabla de categorías */}
    </div>
  );
};

export default AddCategoryForm;
