import React, { useState, useEffect } from "react";
import { useShoppingContext } from "../../../context/ShoppingContext";
import "../../../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const MenuCategory = ({ menuItems, agregarAlCarrito, menuCount }) => {
  const { toggleCart, agregarMenu, carrito, modificarCantidad } =
    useShoppingContext();
  const [productCounts, setProductCounts] = useState({});

  // Obtener la cantidad de cada producto en el carrito
  useEffect(() => {
    const counts = {};
    carrito.forEach((item) => {
      counts[item.producto_id] = item.cantidad;
    });
    setProductCounts(counts);
  }, [carrito]);

  const handleAgregarClick = (menuItem) => {
    if (menuItem.categoria === 0) {
      agregarMenu(menuItem);
    } else {
      agregarAlCarrito(menuItem, `Menu del día ${menuCount + 1}`);
    }
  };

  // Función para restar la cantidad del producto en el carrito
  const handleRestarClick = (menuItem) => {
    const currentCount = productCounts[menuItem.producto_id] || 0;
    if (currentCount > 0) {
      modificarCantidad(menuItem.producto_id, -1);
    }
  };

  return (
    <div className="category-container">
      <div className="menu-category">
        {/* <h2>{category}</h2> */}
        <ul className="menu-container">
          {menuItems
            .filter((menuItem) => menuItem.activo === true)
            .map((menuItem) => (
              <li className="menu-item" key={menuItem.producto_id}>
                <div className="menu-item-content">
                  <div className="description">
                    <h3>{menuItem.nombre}</h3>
                    <div className="details">
                      <p>{menuItem.descripcion}</p>
                      {menuItem.precio_estadio !== 0 && ( // Agrega esta condición para mostrar solo si precio_estadio no es igual a 0
                        <span className="price-real">
                          Precio estadio:{" "}
                          <del>S/. {menuItem.precio_estadio}</del>
                        </span>
                      )}{" "}
                      <br></br>
                      <span className="price">
                        Precio online: S/. {menuItem.precio}
                      </span>
                    </div>
                  </div>
                  <div className="image">
                    <img src={menuItem.img} alt={menuItem.nombre} />
                  </div>
                </div>
                <div className="agregar-container">
                  <div className="quantity-buttons">
                    {productCounts[menuItem.producto_id] > 0 && (
                      <button
                        className="quantity-button"
                        onClick={() => handleRestarClick(menuItem)} // Utiliza la función para restar
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    )}
                    <button
                      className="agregar-button"
                      onClick={() => {
                        handleAgregarClick(menuItem); toggleCart();
                      }}
                    >
                      {productCounts[menuItem.producto_id] > 0 ? (
                        <span>{productCounts[menuItem.producto_id]}</span>
                      ) : (
                        "Comprar"
                      )}
                    </button>
                    {productCounts[menuItem.producto_id] > 0 && (
                      <button
                        className="quantity-button"
                        onClick={() => handleAgregarClick(menuItem)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuCategory;
