import React from "react";
import {
  faMousePointer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Banner from "../../pages/Home/Banner/Banner";
import Timer from "./Timer";
import { useShoppingContext } from "../../context/ShoppingContext";

const CountdownTimer = () => {
  const { setShowPopUp } = useShoppingContext();


  const handleButtonClick = () => {
    setShowPopUp(false);
  };

  return (
    <>
      <div className="countdown-timer">
        <Timer></Timer>
        <Banner></Banner>
        <button href="#" className="cta" onClick={handleButtonClick}>
          Ordena tu combo{" "}
          <FontAwesomeIcon style={{ color: "#fff" }} icon={faMousePointer} />
        </button>
       
      </div>
    </>
  );
};

export default CountdownTimer;
