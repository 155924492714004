import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import {
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import "./UserMenu.css";
import SignUpForm from "../../User/SignUpForm";
import SignInForm from "../../User/SignInForm";
import { useShoppingContext } from "../../../context/ShoppingContext";
import { useAuth } from "../../../context/AuthContext";

const UserMenu = () => {
  const location = useLocation();
  const { authenticatedUser, handleLogout } = useAuth();
  const {
    vaciarCarrito,
    setSelectedItems,
    selectedItemsOriginales,
    toggleUserMenu,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
    cartOpen,
    toggleCart,
  } = useShoppingContext();

  // Número de WhatsApp y mensaje
  const phoneNumber = "+51949833976";
  const message = "¡Hola El Chácha! Necesito ayuda con un pedido.";

  // Crear la URL de WhatsApp
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;

  return (
    <>
      {authenticatedUser ? (
        <div className="user-menu">
          <ul className="user-menu-flex">
            <li className="flex circles">
              <div className="soporte">
                <div>
                  <a
                    href={whatsappUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      className="headset-icon"
                      icon={faHeadset}
                    />
                  </a>
                </div>
              </div>
            </li>

            <li className="datos-personales">
              <p>
                {authenticatedUser.nombre} {authenticatedUser.apellido}
              </p>

              <p>DNI/CE: {authenticatedUser.dni}</p>
              <p>{authenticatedUser.telefono}</p>
              <p>{authenticatedUser.email}</p>
            </li>

            <li className="flex-space-around pd-20 pd-bt-0">
              <Link to="/cuenta/datos-personales">
                <button className="editar-dp" onClick={toggleUserMenu}>
                  editar datos
                </button>
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  toggleUserMenu();
                  vaciarCarrito();
                  setSelectedItems([...selectedItemsOriginales]);
                }}
                className="logout-btn"
              >
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      ) : (
        <>
          {location.pathname !== "/login" &&
            location.pathname !== "/registrarse" && (
              <div className="user-menu">
                <div className="flex-space-around pd-20">
                <button
                    className={`logout-btn`}
                    onClick={() => {
                      if (cartOpen) {
                        toggleCart();
                      }

                      if (showLoginForm) {
                        setShowLoginForm(false);
                      }

                      if (!showSignUpForm) {
                        setShowSignUpForm(true);
                      } else {
                        setShowSignUpForm(false);
                      }
                    }}
                  >
                    Registrarse
                  </button>
                  <button
                    className={`logout-btn`}
                    to="/login"
                    onClick={() => {
                      if (cartOpen) {
                        toggleCart();
                      }

                      if (!showLoginForm) {
                        setShowLoginForm(true);
                      } else {
                        setShowLoginForm(false);
                      }

                      if (showSignUpForm) {
                        setShowSignUpForm(false);
                      }
                    }}
                  >
                    Iniciar Sesión
                  </button>
                </div>
                  
              </div>
            )}
        </>
      )}

      {!authenticatedUser && showLoginForm && <SignInForm />}
      {!authenticatedUser && showSignUpForm && <SignUpForm />}
    </>
  );
};

export default UserMenu;
