import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useShoppingContext } from "../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./form.css";

const SignInForm = () => {
  const { handleLogin, loginData, setLoginData, error } = useAuth();
  const { showLoginForm, showSignUpForm, setShowLoginForm, setShowSignUpForm, userMenuOpen, toggleUserMenu } =
    useShoppingContext();
  
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleLogin(loginData);
    } catch (error) {
      console.error("Error during login:", error);
    }
    if (showLoginForm) {
      setShowLoginForm(false);
    }
    if (showSignUpForm) {
      setShowSignUpForm(false);
    }
    if (userMenuOpen){
      toggleUserMenu();
    }
    console.log(error);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex-login-container">
        <div className="form-container">
          <div className="close-form-container">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-form"
              onClick={() => {
                setShowLoginForm(false);
                setShowSignUpForm(false);
              }}
            />
          </div>

          <form onSubmit={handleSubmit} className="login-form">
            <Link
              to="/"
              onClick={() => {
                if (showLoginForm) {
                  setShowLoginForm(false);
                }
              }}
            >
              <img style={{display: 'block', margin: '0 auto'}} width="200px" src="/images/logos/icon.webp" alt="Logo" />
            </Link>
            <h1 className="form-title">Iniciar sesión</h1>
            <ul>
              <div className="flex-column">
                <li className="flex-input">
                  <label className="form-label">
                    Dirección de email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={loginData.email}
                    onChange={handleChange}
                    autoComplete="on"
                    className="form-input"
                  />
                </li>
                <li className="flex-input">
                  <label className="form-label">Contraseña</label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={loginData.password}
                      onChange={handleChange}
                      autoComplete="off"
                      className="form-input"
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="password-icon"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </li>
              </div>

              <li>
                <button type="submit" className="form-button">
                  Iniciar sesión
                </button>
              </li>
              <li>ó</li>
              <li>
                <p
                  className="pointer"
                  onClick={() => {
                    if (showLoginForm) {
                      setShowLoginForm(false);
                    }
                    if (!showSignUpForm) {
                      setShowSignUpForm(true);
                    }
                  }}
                >
                  Registrarse
                </p>
              </li>
            </ul>
          </form>
        </div>

        {error === "null" && <div className="error-message">{error}</div>}
      </div>
    </>
  );
};

export default SignInForm;
