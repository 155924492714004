import React from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useShoppingContext } from "../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./form.css";

const SignUpForm = () => {
  const { loginData, setLoginData } = useAuth();
  const { showLoginForm, showSignUpForm, setShowLoginForm, setShowSignUpForm } =
    useShoppingContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Añadir el distrito al objeto loginData
      setLoginData({ ...loginData });
      // Añadir el distrito al objeto loginData
      const dataToSend = {
        ...loginData,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/createUser`,
        dataToSend
      );

      console.log("Respuesta del servidor:", response.data);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
    
    setShowSignUpForm(false);
    setShowLoginForm(true);
  };

  return (
    <>
      <div className="flex-login-container">
        <div className="form-container">
          <div className="close-form-container">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-form"
              onClick={() => {
                if (showLoginForm) {
                  setShowLoginForm(false);
                }
                if (showSignUpForm) {
                  setShowSignUpForm(false);
                }
              }}
            />
          </div>
          <form onSubmit={handleSubmit} className="register-form">
            <Link to="/" onClick={()=> {
                if (showSignUpForm) {
                  setShowSignUpForm(false);
               }
              }}>
              <img style={{display: 'block', margin: '0 auto'}} width="200px" src="/images/logos/icon.webp" alt="Logo" />
            </Link>
            <h1 className="form-title">Registrarse</h1>
            <div className="flex-space-around">
              <label className="form-label">
                Nombre
                <input
                  type="text"
                  name="nombre"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Apellido
                <input
                  type="text"
                  name="apellido"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
            </div>
            <div className="flex-space-around">
              <label className="form-label">
                Email
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Teléfono
                <input
                  type="text"
                  name="telefono"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
            </div>

            <div className="flex">
              <label className="form-label">
                DNI/CE
                <input
                  type="text"
                  name="dni"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
            </div>

            <div className="flex">
              <label className="form-label">
                Contraseña
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
            </div>

            <ul>
              <li>
                <button type="submit" className="form-button">
                  Registrarse
                </button>
              </li>
              <li>ó</li>
              <li>
                <p
                  className="pointer"
                  onClick={() => {
                    if (showSignUpForm) {
                      setShowSignUpForm(false);
                    }
                    if (!showLoginForm) {
                      setShowLoginForm(true);
                    }
                  }}
                >
                  Iniciar Sesión
                </p>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
