import React from "react";
import axios from "axios";
import { useShoppingContext } from "../../../context/ShoppingContext";

const CategoryTable = () => {
  const { categorias, setCategorias } = useShoppingContext();
  
  const handleChangeActivo = async (categoria_id, newValue) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/categorias/${categoria_id}`, { activo: newValue === 'true' });
      // Si la solicitud se completó correctamente, actualiza el estado de la categoría
      if (response.status === 200) {
        // Clona el array de categorías
        const newCategories = [...categorias];
        // Encuentra la categoría en el array
        const categoryIndex = newCategories.findIndex(category => category.categoria_id === categoria_id);
        // Actualiza el valor activo de la categoría encontrada
        newCategories[categoryIndex].activo = newValue === 'true';
        // Actualiza el estado con el nuevo array de categorías
        setCategorias(newCategories);
      }
    } catch (error) {
      console.error('Error al actualizar el estado activo de la categoría:', error);
    }
  };

  return (
    <div>
      <h2>Lista de Categorías</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Activo</th>
          </tr>
        </thead>
        <tbody>
          {categorias.map((categoria) => (
            <React.Fragment key={categoria.categoria_id}>
              <tr>
                <td>{categoria.categoria_id}</td>
                <td>{categoria.nombre}</td>
                <td>
                  <select
                    value={categoria.activo ? "true" : "false"}
                    onChange={(e) => handleChangeActivo(categoria.categoria_id, e.target.value)}
                  >
                    <option value="true">Sí</option>
                    <option value="false">No</option>
                  </select>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
