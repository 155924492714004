import React, { useState, useEffect } from "react";
import axios from "axios";
import ListaProductos from "./ListaProductos";
import "../../../styles.css";
import AddCategoryForm from "../Categorias/AddCategoryForm";
import { useShoppingContext } from "../../../context/ShoppingContext";

const AgregarMenu = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    descripcion: "",
    img: "",
    stock: "",
    categoria_id: "",
    precio_estadio: "",
  });

  const {categorias, cargarCategorias, productos, cargarProductos} = useShoppingContext();

  useEffect(() => {
    cargarProductos();
    cargarCategorias();
  }, [cargarCategorias, cargarProductos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        await axios.post(
            `${process.env.REACT_APP_API_URL}/products/`,
            formData,
            { withCredentials: true }
        );

        setFormData({
            nombre: "",
            descripcion: "",
            img: "",
            stock: "",
            categoria_id: "",
            precio_estadio: "",
        });

        cargarProductos();
        cargarCategorias();
    } catch (error) {
        console.error("Error al enviar la solicitud:", error);
    }
};


  return (
    <div className="content-container">
      <AddCategoryForm />
      <div>
        <h2>Agregar Producto</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Nombre:
            <input
              required
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
            />
          </label>
          <label>
            Descripción:
            <input
              type="text"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
            />
          </label>
          <label>
            Imagen:
            <input
              type="text"
              name="img"
              value={formData.img}
              onChange={handleChange}
            />
          </label>
          <label>
            Stock:
            <input
              required
              type="number"
              name="stock"
              value={formData.stock}
              onChange={handleChange}
            />
          </label>
          <label>
            Precio:
            <input
              type="number"
              name="precio"
              value={formData.precio}
              onChange={handleChange}
            />
          </label>
          <label>
            Precio estadio:
            <input
              type="number"
              name="precio_estadio"
              value={formData.precio_estadio}
              onChange={handleChange}
            />
          </label>
          <label>
            Categoría:
            <select
              required
              name="categoria_id"
              value={formData.categoria_id}
              onChange={handleChange}
            >
              <option value="">Selecciona una categoría</option>
              {categorias.map((categoria) => (
                <option key={categoria.categoria_id} value={categoria.categoria_id}>
                  {categoria.nombre}
                </option>
              ))}
            </select>
          </label>
          <button type="submit">Registrar producto</button>
        </form>
      </div>

      <ListaProductos productos={productos} />
    </div>
  );
};

export default AgregarMenu;
