import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useShoppingContext } from "../../context/ShoppingContext";
import axios from "axios";
import "./PedidoConfirmado.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading/Loading";

const PedidoDetalle = () => {
  const {
    obtenerDetallesPedido,
    detallesPedido,
    obtenerProductos,
    productosOriginales,
  } = useShoppingContext();
  const [pedidoConfirmado, setPedidoConfirmado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [yape, setYape] = useState(true);
  const { pedidoId, trackId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pedidoResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`),
          obtenerDetallesPedido(pedidoId),
          obtenerProductos(),
        ]);

        setPedidoConfirmado(pedidoResponse.data);
      } catch (error) {
        console.error("Error al obtener los detalles del pedido:", error);
      } finally {
        setYape(false);
      }
    };

    fetchData();
  }, [pedidoId, obtenerDetallesPedido, obtenerProductos, trackId, navigate]);

  const renderPedidoStatus = () => {
    if (!yape) {
      const { estado_pedido } = pedidoConfirmado;

      switch (estado_pedido) {
        case "Activo":
          return (
            <div>
              <h4>¡Gracias por tu compra, {pedidoConfirmado?.nombre}! </h4>
              <h2 className="pedido-titulo activo-text">
                Envíanos tu comprobante de pago por WhatsApp!
              </h2>
              <div className="yape-image-container">
                <div className="purple-div">
                  <img
                    src="/images/yape.jpg"
                    height={250}
                    alt="Yape"
                    className="yape-image"
                  />
                  <ul className="instruction-list">
                    <li>
                      <p className="copiar">
                        S/ {pedidoConfirmado?.monto_total.toFixed(2)}
                      </p>
                      {!copiedToClipboard && (
                        <b className="copiar">{yapeNumber}</b>
                      )}
                      <div className="copiar" onClick={handleCopyToClipboard}>
                        {!copiedToClipboard && (
                          <span>
                            <FontAwesomeIcon icon={faCopy} /> Copiar
                          </span>
                        )}
                        {copiedToClipboard && (
                          <span className="numero-copiado">
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <br></br> Número copiado
                          </span>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=+51949833976&text=ID de pedido: ${pedidoConfirmado?.id}.%0D%0A Cliente: ${pedidoConfirmado?.nombre}.%0D%0A Método de pago: ${pedidoConfirmado?.metodo_pago}%0D%0A ¡Hola! realicé mi pedido desde El Chácha web. Quiero coordinar mi entrega.`}
              >
                <dt className="hazclickaqui">
                  HAZ CLICK AQUÍ PARA INICIAR CHAT
                </dt>
              </a>
              <dt>Fecha de creación</dt>
              <dd>
                {formattedCreatedAt} a las {formattedCreatedTime}
              </dd>
            </div>
          );
        case "Confirmado":
          return (
            <div className="mg-b-10">
              <h4>{pedidoConfirmado?.nombre}</h4>
              <h4>DNI {pedidoConfirmado?.dni}</h4>
              <h2 className="pedido-titulo en-camino-text">
                Pago exitoso, acércate con tu documento de identidad!
              </h2>
              <b style={{ textAlign: "center" }}>
                Pedido confirmado el {formattedUpdatedAt}
              </b>

              <dt>Fecha de creación</dt>
              <dd>
                {formattedCreatedAt} a las {formattedCreatedTime}
              </dd>
            </div>
          );

        case "Finalizado":
          return (
            <div className="mg-b-10">
              <h2 className="pedido-titulo finalizado-text">
                ¡Tu pedido ha sido entregado con éxito!
              </h2>
              <b style={{ textAlign: "center" }}>
                Pedido entregado el {formattedUpdatedAt}
              </b>
            </div>
          );
        default:
          return null;
      }
    }

    return null;
  };

  // Assuming detallesPedido is an object containing pedido details
  const formattedCreatedAt = pedidoConfirmado?.createdAt
    ? new Date(pedidoConfirmado.createdAt).toLocaleDateString("es-PE", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "-";
  const formattedCreatedTime = pedidoConfirmado?.createdAt
    ? new Date(pedidoConfirmado.createdAt).toLocaleTimeString("es-PE", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "-";

  const formattedUpdatedAt = pedidoConfirmado?.updatedAt
    ? new Date(pedidoConfirmado.updatedAt).toLocaleTimeString("es-PE", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "-";

  const yapeNumber = 988883286;
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const handleCopyToClipboard = () => {
    const copyText = yapeNumber;
    const textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedToClipboard(true);

    // Después de un tiempo, restablecer el estado para ocultar el mensaje
    setTimeout(() => {
      setCopiedToClipboard(false);
      // Agregar la clase 'hidden' a purple-div después de 3 segundos
      setTimeout(() => {
        const purpleDiv = document.querySelector(".purple-div");
        purpleDiv.classList.add("hidden");
        const yapeImageContainer = document.querySelector(
          ".yape-image-container"
        ); // Establecer loading a true antes de abrir la ventana emergente
        setLoading(true);
        yapeImageContainer.classList.add("hidden");

        // Abrir el enlace después de 3 segundos
        setTimeout(() => {
          setLoading(false); // Restablecer loading a false
          window.open("https://innovacxion.page.link/mVFa", "_blank");
        }, 2000);
      }, 0);
    }, 2000);
  };

  return (
    <div className="confirmar-pedido">
      {loading && <Loading text="abriendo la app yape" />}

      <section className="pedido-details">
        {renderPedidoStatus()}
        <b>
          Acércate con tu DNI a cualquier puesto de "El Chácha" en la tribuna
          Sur durante el evento y reclama tu combo.
        </b>
      </section>

      <div className="pedido-card-container">
        {Array.isArray(detallesPedido) && detallesPedido.length > 0 ? (
          detallesPedido.map((detalle, index) => {
            const productoDelDetalle = productosOriginales.find(
              (producto) => producto.producto_id === detalle.producto_id
            );
            return (
              <React.Fragment key={index}>
                <article className="pedido-card">
                  <img
                    alt="imagen del producto"
                    src={productoDelDetalle?.img || "Cargando..."}
                  />
                  <div className="data">
                    <strong>{productoDelDetalle?.nombre || ""}</strong>
                    <br></br>
                    <small>{productoDelDetalle?.descripcion || ""}</small>
                    
                    <p>
                      <strong>Cantidad:</strong> {detalle.cantidad}
                    </p>
                    <p>
                      <strong>Precio:</strong> S/.{" "}
                      {detalle.precio_unitario.toFixed(2)}
                    </p>
                  </div>
                </article>
              </React.Fragment>
            );
          })
        ) : (
          <p>Creando pedido.</p>
        )}
      </div>
    </div>
  );
};

export default PedidoDetalle;
