import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const GoogleAnalyticsTracker = ({ trackingID }) => {
  useEffect(() => {
    // Inicializa Google Analytics con el ID de seguimiento proporcionado
    ReactGA.initialize(trackingID);

    // Registra la página inicial al montar el componente
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Retorna una función de limpieza para limpiar el efecto
    return () => {};
  }, [trackingID]);

  // Función para rastrear eventos clave en toda la aplicación
  const trackEvent = (category, action, label, value) => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };

  return null; // Este componente no renderiza nada en la interfaz
};

export default GoogleAnalyticsTracker;
