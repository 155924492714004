import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useShoppingContext } from "../../../context/ShoppingContext";

const EditarProducto = ({ producto, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({});
  const { setCategorias } = useShoppingContext();

  const obtenerCategorias = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categorias`
      );
      setCategorias(response.data);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  }, [setCategorias]);

  useEffect(() => {
    if (producto) {
      setFormData(producto);
    }
    obtenerCategorias();
  }, [producto, obtenerCategorias]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/products/${formData.producto_id}`,
        formData
      );
      console.log("Respuesta del servidor:", response.data);

      // Llama a la función onUpdate para actualizar la lista de productos
      onUpdate(response.data);

      // Cierra el modal
      onClose();
    } catch (error) {
      console.error("Error al actualizar el producto:", error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Editar Producto</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Nombre:
            <input
              type="text"
              name="nombre"
              value={formData.nombre || ""}
              onChange={handleChange}
            />
          </label>
          <label>
            Descripción:
            <input
              type="text"
              name="descripcion"
              value={formData.descripcion || ""}
              onChange={handleChange}
            />
          </label>
          <label>
            Stock:
            <input
              type="number"
              name="stock"
              value={formData.stock || ""}
              onChange={handleChange}
            />
          </label>
          <label>
            Precio:
            <input
              type="number"
              name="precio"
              value={formData.precio || ""}
              onChange={handleChange}
            />
          </label>
          <label>
            Precio estadio:
            <input
              type="number"
              name="precio_estadio"
              value={formData.precio_estadio || ""}
              onChange={handleChange}
            />
          </label>
          <label>
            Imagen:
            <input
              type="text"
              name="img"
              value={formData.img || ""}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Actualizar Producto</button>
        </form>
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default EditarProducto;
