import React from "react";
import { useShoppingContext } from "../../context/ShoppingContext";
import { Link } from "react-router-dom";
import "./Details.css";
import "./Checkout.css";

const Details = () => {
  const { carrito } = useShoppingContext();

  // Si el carrito está vacío, mostrar un enlace de vuelta al menú
  if (carrito.length === 0) {
    return (
      <div className="empty-cart">
        <p>No hay elementos en el carrito</p>
        <Link to="/menu">Volver al menú</Link>
      </div>
    );
  }

  return (
    <table className="product-table">
      <thead>
        <tr>
          <th colSpan={2}>Producto</th>
          <th>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        {carrito.map((item, index) => {
          const precioUnitario = item.precio.toFixed(2);
          const monto_total = item.precio * item.cantidad;
          return (
            <tr key={`${item.producto_id}-${index}`}>
              <td className="flex" style={{ alignItems: "center" }}>
                <img src={item.img} alt="imagen del producto" />
              </td>
              <td  style={{ alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "8px",
                  }}
                >
                  <span className="product-name">{item.nombre}</span>
                  <span>S/. {precioUnitario}</span>
                  <b className="product-quantity">x{item.cantidad}</b>
                </div>
              </td>
              <td>S/. {monto_total.toFixed(2)}</td>
            </tr>
          );
        })}

        <tr className="cart-total">
          <td colSpan="2">
            <b>Total a pagar</b>
          </td>
          <td>S/. {carrito.reduce((total, item) => total + item.precio * item.cantidad, 0).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Details;
