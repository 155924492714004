import React, { useState, useEffect } from "react";
import axios from "axios";
import MenuCategory from "./MenuCategory";
import "./Menu.css";

import { useShoppingContext } from "../../../context/ShoppingContext";

import Banner from "../Banner/Banner";
import CountdownTimer from "../../../components/Countdown/CountdownTimer";
import Timer from "../../../components/Countdown/Timer";

const Menu = () => {
  const {
    agregarAlCarrito,
    modificarCantidad,
    toggleCart,
    showPopUp,
    setShowPopUp,
  } = useShoppingContext();
  const [categoriasConProductos, setCategoriasConProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtroCategoria, setFiltroCategoria] = useState(null);
  const [nombreCategoriaFiltrada, setNombreCategoriaFiltrada] = useState("");

  const handleFiltrarCategoria = (categoria_id, nombre) => {
    setFiltroCategoria(categoria_id);
    setNombreCategoriaFiltrada(nombre);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categorias`)
      .then((response) => {
        const categoriasData = response.data;
        if (Array.isArray(categoriasData)) {
          const categoriasActivas = categoriasData.filter(
            (categoria) => categoria.activo
          );
          setCategoriasConProductos(categoriasActivas);
          if (categoriasActivas.length === 1) {
            // Si solo hay una categoría activa, filtrar automáticamente por ella
            setFiltroCategoria(categoriasActivas[0].categoria_id);
            setNombreCategoriaFiltrada(categoriasActivas[0].nombre);
          }
          setLoading(false);
        } else {
          console.error("No hay categorías agregadas.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error al obtener categorías:", error);
        setLoading(false);
      });
  }, [nombreCategoriaFiltrada]);

  useEffect(() => {
    // Mostrar el popup durante 10 segundos
    if (showPopUp) {
      const timer = setTimeout(() => {
        setShowPopUp(false); // Ocultar el popup después de 10 segundos
      }, 4000);

      return () => clearTimeout(timer); // Limpiar el timer al desmontar el componente
    }
  }, [showPopUp, setShowPopUp]);

  return (
    <div className="store-container">
    <h1 className="hidden">Pedido Online para el Partido Alianza Lima vs UTC - Alianza Lima - Combo - Bebida y Snacks</h1>
    <h2 className="hidden">¡No te pierdas ni un minuto del juego con nuestro servicio de pedidos anticipados!Liga 1 - Alianza Lima</h2>
      {showPopUp && <CountdownTimer></CountdownTimer>}
      <Timer></Timer>
      <Banner />
      {categoriasConProductos.length > 1 && (
        <div className="categoria-filtros">
          <button
            className={filtroCategoria === null ? "categoria-activa" : ""}
            onClick={() => {
              setFiltroCategoria(null);
              setNombreCategoriaFiltrada("");
            }}
          >
            Todos
          </button>
          {categoriasConProductos.map((categoria) => (
            <button
              key={categoria.categoria_id}
              className={
                filtroCategoria === categoria.categoria_id
                  ? "categoria-activa"
                  : ""
              }
              onClick={() =>
                handleFiltrarCategoria(categoria.categoria_id, categoria.nombre)
              }
            >
              {categoria.nombre}
            </button>
          ))}
        </div>
      )}

      {loading ? (
        <p>Cargando productos...</p>
      ) : (
        <div className="menu-options">
          <MenuCategoryWrapper
            agregarAlCarrito={agregarAlCarrito}
            modificarCantidad={modificarCantidad}
            toggleCart={toggleCart}
            filtroCategoria={filtroCategoria}
            nombreCategoriaFiltrada={nombreCategoriaFiltrada}
            categoriasConProductos={categoriasConProductos}
          />
        </div>
      )}
    </div>
  );
};

const MenuCategoryWrapper = ({
  agregarAlCarrito,
  modificarCantidad,
  toggleCart,
  filtroCategoria,
  nombreCategoriaFiltrada,
  categoriasConProductos,
}) => {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    let params = { activo: true };
    if (filtroCategoria) {
      params.categoria_id = filtroCategoria;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: params,
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
        } else {
          console.error("No hay productos agregados para esta categoría.");
        }
      })
      .catch((error) => {
        console.error(
          `Error al obtener productos para la categoría ${nombreCategoriaFiltrada}:`,
          error
        );
      });
  }, [filtroCategoria, nombreCategoriaFiltrada]);

  let productosFiltrados;
  if (filtroCategoria) {
    productosFiltrados = productos.filter(
      (producto) => producto.categoria_id === filtroCategoria
    );
  } else {
    productosFiltrados = productos.filter((producto) =>
      categoriasConProductos.find(
        (categoria) =>
          categoria.categoria_id === producto.categoria_id && categoria.activo
      )
    );
  }

  return (
    <MenuCategory
      category={
        nombreCategoriaFiltrada
          ? nombreCategoriaFiltrada
          : "Todos los productos"
      }
      menuItems={productosFiltrados}
      agregarAlCarrito={agregarAlCarrito}
      modificarCantidad={modificarCantidad}
      toggleCart={toggleCart}
    />
  );
};

export default Menu;
