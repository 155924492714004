import React from "react";
import {
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({text}) => {
  return (
    <div className="cargando">
      <h1 className="cargando-text">
        <span className="intercalado">{text}</span>
        <FontAwesomeIcon className="spinner" icon={faSpinner}></FontAwesomeIcon>
      </h1>
    </div>
  );
};

export default Loading;
