// Carrito.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./carrito.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faPlus,
  faMinus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useShoppingContext } from "../../context/ShoppingContext";


const Carrito = ({
  carrito,
  eliminarDelCarrito,
  modificarCantidad,
  toggleCart,
  cartOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItemCount } = useShoppingContext();

  const handleModificarCantidad = (itemId, cantidad) => {
    modificarCantidad(itemId, cantidad);
  };


  const calcularSubtotal = () => {
    let subtotal = 0;

    // Verificar si carrito tiene un valor antes de iterar sobre él
    if (carrito) {
      // Sumar el precio total de cada producto en el carrito
      carrito.forEach((item) => {
        subtotal += calcularPrecioTotal(item) * item.cantidad;
      });
    }

    return subtotal;
  };

  const calcularPrecioTotal = (producto) => {
    let precioTotal = producto.precio;

    // Verificar si producto.extras está definido antes de iterar sobre él
    if (producto.extras) {
      // Sumar el precio de cada extra al precio total
      producto.extras.forEach((extra) => {
        precioTotal += extra.precio * extra.cantidad;
      });
    }

    return precioTotal;
  };


  return (
    <>
      <div className="cart-container" onClick={toggleCart}></div>
      <div className="close-cart" onClick={toggleCart}></div>
      <div className={`carrito-sidebar ${cartOpen ? "open" : ""}`}>
        <div className="carrito-close-button" onClick={toggleCart}>
          <FontAwesomeIcon icon={faTimes} className="carrito-close-icon" />
        </div>
        <div className="carrito-container">
          {carrito.length === 0 ? (
            <div className="empty-cart">
              <p>No has seleccionado ningún producto.</p>
              <Link
                to="/menu"
                className="continue-shopping"
                onClick={toggleCart}
              >
                Ir a comprar
              </Link>
            </div>
          ) : (
            <>
              <h2 className="carrito-title">TU CARRITO</h2>
              <ul className="carrito-items">
                <ul className="instructions-list">
                  <h2>Pasos para realizar tu compra</h2>
                  <li>
                    <div className="circle">1</div> Selecciona la cantidad
                  </li>
                  <li>
                    <div className="circle">2</div> Ir a pagar
                  </li>
                  <li>
                    <div className="circle">3</div> Ingresa tus datos
                  </li>
                  <li>
                    <div className="circle">4</div> Confirma tu pedido
                  </li>
                  <li>
                    <div className="circle">5</div> Envíanos el comprobante
                  </li>
                </ul>

                {carrito.map((item, index) => (
                  <li
                    className="carrito-item"
                    key={`${item.producto_id}-${index}`}
                  >
                    <div className="image-product-price">
                      <img
                        className="carrito-img"
                        alt="producto"
                        src={item.img}
                      ></img>
                      <div className="product-price">
                        <h4>
                          {item.cantidad < 10 ? "0" : ""}
                          {item.cantidad} x {item.nombre}
                        </h4>
                        {item.extras && item.extras.length > 0 && (
                          // Mostrar extras
                          <div>
                            <h3>Extras:</h3>
                            <ul>
                              {item.extras.map((extra, index) => (
                                <li key={`${extra.id}-${index}`}>
                                  <p>
                                    {extra.nombre} (+ S/.{" "}
                                    {extra.precio.toFixed(2)}) x{" "}
                                    {extra.cantidad}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="cantidad-precio">
                          <p>S/. {calcularPrecioTotal(item).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>

                    <div className="quantity-trash">
                      <div
                        className="trash"
                        onClick={() => {
                          eliminarDelCarrito(item.producto_id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>

                      <div className="quantity-cell">
                        <div
                          className="quantity-button"
                          onClick={() => {
                            handleModificarCantidad(
                              item.producto_id,
                              -1,
                              item.id_categoria
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </div>
                        <span className="item-quantity">{item.cantidad}</span>
                        <div
                          className="quantity-button"
                          onClick={() => {
                            handleModificarCantidad(
                              item.producto_id,
                              1,
                              item.categoria_id
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
                <div className="subtotal">
                  <p>Costo de los productos</p>
                  <p>S/. {calcularSubtotal().toFixed(2)}</p>
                </div>
              </ul>

              <div className="carrito-options-fixed">
                <div className="opciones-carrito">
                  <button
                    className="carrito-button carrito-button-pedido"
                    onClick={() => {
                      if (location.pathname === "/checkout/payment") {
                        toggleCart();
                      } else {
                        toggleCart();
                        navigate("/checkout/shipping");
                      }
                    }}
                  >
                    <p className="item-count">{cartItemCount}</p>
                    <p>IR A PAGAR</p>
                    <p>S/. {calcularSubtotal().toFixed(2)}</p>
                  </button>
                  <button
                    className="carrito-button seguir-comprando"
                    onClick={() => {
                      toggleCart();
                      navigate("/");
                    }}
                  >
                    SEGUIR COMPRANDO
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Carrito;
