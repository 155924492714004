import React, { useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";
import { useAuth} from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import Carrito from "../../pages/Carrito/Carrito";
import { useShoppingContext } from "../../context/ShoppingContext";
import SignUpForm from "../User/SignUpForm";
import SignInForm from "../User/SignInForm";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const { authenticatedUser } = useAuth();
  const location = useLocation();

  // Número de WhatsApp y mensaje
  const phoneNumber = "+51949833976";
  const message = "¡Hola Chácha! Necesito ayuda con mi pedido.";

  // Crear la URL de WhatsApp
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;

  const {
    carrito,
    eliminarDelCarrito,
    modificarCantidad,
    setProductos,
    toggleCart,
    closeCart,
    cartOpen,
    showLoginForm,
    showSignUpForm,
    cartItemCount,
    setCartItemCount,
    setOpcionesVisible,
  } = useShoppingContext();

  // Verificar si la ubicación actual es "/login" o "/registrarse"
  const isLoginPage = location.pathname === "/login";
  const isSignUpPage = location.pathname === "/registrarse";



  const hideOpciones = () => {
    setOpcionesVisible(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: {
          activo: true,
        },
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
        } else {
          console.error("No hay productos agregados.");
        }
      })
      .catch((error) => {
        console.error("Error al obtener productos activos:", error);
      });
  }, [setProductos]);

  useEffect(() => {
    const totalItems = carrito.reduce(
      (total, item) => total + item.cantidad,
      0
    );
    setCartItemCount(totalItems);
  }, [carrito, setCartItemCount]);

  return (
    <>
      {!(isLoginPage || isSignUpPage) && (
        <footer className="footer-container">
          <ul className="footer-ul">
            <li>
              <button
                onClick={() => {
                  toggleCart();
                  hideOpciones();
                }}
                className="cart-div"
              >
                <FontAwesomeIcon
                  style={{ color: "#000" }}
                  icon={faShoppingCart}
                />
                {cartItemCount > 0 && (
                  <span className="cart-item-count">{cartItemCount}</span>
                )}
              </button>
            </li>

            <li className="payment_methods">
              <a
                href={whatsappUrl}
                className="wa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="escribenos"> Escríbenos </span>
                <FontAwesomeIcon icon={faWhatsapp} />{" "}
              </a>
            </li>
          </ul>

          {cartOpen && (
            <Carrito
              carrito={carrito}
              eliminarDelCarrito={eliminarDelCarrito}
              modificarCantidad={modificarCantidad}
              cartOpen={cartOpen}
              toggleCart={toggleCart}
            />
          )}
          {cartOpen && <div className="overlay" onClick={closeCart}></div>}
        </footer>
      )}
      {!authenticatedUser && showLoginForm && <SignInForm />}
      {!authenticatedUser && showSignUpForm && <SignUpForm />}
    </>
  );
};

export default Footer;
