import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Details from "./Details";
import "../../styles.css";
import "./Checkout.css";
import "./Details.css";
import { useAuth } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useShoppingContext } from "../../context/ShoppingContext";

const CheckoutShipping = () => {
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [telefono, setTelefono] = useState("");
  const [dni, setDni] = useState("");

  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);

  const { authenticatedUser } = useAuth();

  const {
    detailsVisible,
    setDetailsVisible,
    isRotated,
    setRotated,
    showLoginSection,
    setShowLoginSection,
    toggleCart,
    cartOpen,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
  } = useShoppingContext();

  useEffect(() => {
    if (authenticatedUser) {
      setNombre(authenticatedUser.nombre || "");
      setApellidos(authenticatedUser.apellido || "");
      setEmail(authenticatedUser.email || "");
      setTelefono(authenticatedUser.telefono || "");
      setDni(authenticatedUser.dni || "");
    } else {
      setNombre("");
      setApellidos("");
      setEmail("");
      setTelefono("");
      setDni("");
    }
  }, [authenticatedUser]);

  const handleInputChange = (e, setStateFunction) => {
    setStateFunction(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let shippingInfo;

    if (email && nombre && apellidos && telefono) {
      shippingInfo = {
        email,
        dni,
        nombre,
        apellidos,
        telefono,
      };
    } else if (changeEmail && nombre && apellidos && telefono && dni) {
      shippingInfo = {
        email: newEmail,
        dni,
        nombre,
        apellidos,
        telefono,
      };
    }

    if (shippingInfo) {
      navigate("/checkout/payment", { state: { shippingInfo } });
    } else {
      alert("Por favor, complete todos los campos.");
    }
  };

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
    setRotated(!isRotated);
  };

  const handleSkipLogin = () => {
    setShowLoginSection(false);
  };

  return (
    <div className="confirmar-pedido">
      <h2 className="title">Información de contacto</h2>
      {/* Sección adicional para iniciar sesión si el usuario no está autenticado */}
      {showLoginSection && !authenticatedUser && (
        <div className="login-section">
          <span>
            ¿Ya tienes una cuenta?{" "}
            <a
              onClick={() => {
                if (cartOpen) {
                  toggleCart();
                }

                if (!showLoginForm) {
                  setShowLoginForm(true);
                } else {
                  setShowLoginForm(false);
                }

                if (showSignUpForm) {
                  setShowSignUpForm(false);
                }
              }}
            >
              Inicia sesión
            </a>{" "}
            o{" "}
            <a
              onClick={() => {
                if (cartOpen) {
                  toggleCart();
                }

                if (showLoginForm) {
                  setShowLoginForm(false);
                }

                if (!showSignUpForm) {
                  setShowSignUpForm(true);
                } else {
                  setShowSignUpForm(false);
                }
              }}
            >
              Regístrate
            </a>
            .
          </span>
          <span className="omitir" type="button" onClick={handleSkipLogin}>
            <FontAwesomeIcon icon={faTimes} /> Comprar sin iniciar sesión{" "}
          </span>
        </div>
      )}
      <form className="checkout-form-container" onSubmit={handleSubmit}>
        <div className="form">
          <div className="row">
            <div id="nombre-container" className="flex-form-input">
              <label htmlFor="nombre" className="label">
                Nombre <span className="color-red-bold">*</span>
              </label>
              <input
                type="text"
                id="nombre"
                value={nombre}
                onChange={(e) => handleInputChange(e, setNombre)}
                className="input"
                required
              />
            </div>
            <div id="apellidos-container" className="flex-form-input">
              <label id="apellidos-label" htmlFor="apellidos" className="label">
                Apellidos <span className="color-red-bold">*</span>
              </label>
              <input
                type="text"
                id="apellidos"
                value={apellidos}
                onChange={(e) => handleInputChange(e, setApellidos)}
                className="input"
                required
              />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="email" className="label">
              Correo Electrónico <span className="color-red-bold">*</span>
            </label>
            {authenticatedUser && !changeEmail ? (
              <div className="change-email-section">
                <span className="email-de-usuario">
                  {authenticatedUser.email}
                </span>
                <br></br>
                <span className="cambiar" onClick={() => setChangeEmail(true)}>
                  Cambiar
                </span>
              </div>
            ) : (
              <input
                type="email"
                id="email"
                value={changeEmail ? newEmail : email}
                onChange={(e) =>
                  changeEmail
                    ? setNewEmail(e.target.value)
                    : handleInputChange(e, setEmail)
                }
                className="input"
                required
                disabled={authenticatedUser && !changeEmail}
              />
            )}
          </div>
          <div className="flex-space-between">
            <div className="input-group">
              {" "}
              <label htmlFor="dni" className="label">
                DNI/CE <span className="color-red-bold">*</span>
              </label>
              <input
                type="dni"
                id="dni"
                value={dni}
                onChange={(e) => handleInputChange(e, setDni)}
                className="input"
                required
                maxLength={8}
              />
            </div>
            <div className="input-group">
              <label htmlFor="telefono" className="label">
                Teléfono <span className="color-red-bold">*</span>
              </label>
              <input
                type="tel"
                id="telefono"
                value={telefono}
                onChange={(e) => handleInputChange(e, setTelefono)}
                className="input"
                required
                maxLength={9}
              />
            </div>
          </div>

          <div
            className="flex-space-between ver-pedido"
            onClick={toggleDetails}
          >
            <div className="buttons">
              <div className="ver-pedido" onClick={toggleDetails}>
                VER MI PEDIDO
              </div>
            </div>

            <div className="close-div" onClick={toggleDetails}>
              <FontAwesomeIcon
                icon={faPlus}
                className={`close-icon ${isRotated ? "rotate" : ""}`}
              />
            </div>
          </div>
          <div style={{ display: detailsVisible ? "block" : "none" }}>
            {" "}
            <Details />
          </div>
          <div className="flex-buttons">
            <div onClick={() => toggleCart()} className="checkout-option">
              editar carrito
            </div>

            <input
              type="submit"
              value="Siguiente"
              className="next-step-button"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CheckoutShipping;
