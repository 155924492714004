import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./Checkout.css";
import Details from "./Details";
import { useLocation } from "react-router-dom";
import { useShoppingContext } from "../../context/ShoppingContext";
import { useAuth } from "../../context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../components/Loading/Loading";

const CheckoutPayment = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { shippingInfo } = location.state || {};
  const {
    calcularTotal,
    carrito,
    vaciarCarrito,
    setSelectedItems,
    selectedItemsOriginales,
    metodoPago,
  } = useShoppingContext();
  const montoTotal = calcularTotal(carrito);
  const { authenticatedUser } = useAuth();
  const { toggleCart } = useShoppingContext();
  const [trackId, setTrackId] = useState(null);
  const soundRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlTrackId = searchParams.get("trackId");
    if (urlTrackId) {
      setTrackId(urlTrackId);
    } else {
      setTrackId(uuidv4());
    }
  }, [location.search]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const user_id = authenticatedUser ? authenticatedUser.id : "0";

      const pedidoTrackId = trackId;

      console.log("Enviando solicitud para crear pedido...");

      const pedidoResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/crear-pedido`,
        {
          user_id,
          monto_total: montoTotal,
          dni: `${shippingInfo.dni}`,
          nombre: `${shippingInfo.nombre} ${shippingInfo.apellidos}`,
          telefono: `${shippingInfo.telefono}`,
          email: `${shippingInfo.email}`,
          metodo_pago: metodoPago,
          estado_pedido: "Activo",
          track_id: pedidoTrackId,
        }
      );

      const nuevoPedidoId = pedidoResponse.data.pedido.id;

      for (const [index, producto] of carrito.entries()) {
        console.log(
          `Enviando solicitud para crear detalle de pedido (${index + 1}/${
            carrito.length
          })...`
        );

        const detallePedidoResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/detalles-de-pedido`,
          {
            user_id,
            pedido_id: nuevoPedidoId,
            producto_id: producto.producto_id,
            cantidad: producto.cantidad,
            precio_unitario: producto.precio,
          }
        );
        console.log(
          `Respuesta del servidor al crear detalle de pedido (${index + 1}/${
            carrito.length
          }):`,
          detallePedidoResponse
        );
      }

      console.log("Respuesta del servidor al crear pedido:", pedidoResponse);
      if (pedidoResponse.status === 201) {
        navigate(`/pedido-confirmado/${nuevoPedidoId}/${pedidoTrackId}`);
      }

      vaciarCarrito();
      setSelectedItems([...selectedItemsOriginales]);
      setLoading(false);
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      setErrorMessage(
        "Hubo un error al procesar el pago. Por favor, inténtalo de nuevo."
      );
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlTrackId = searchParams.get("trackId");
    if (urlTrackId) {
      setTrackId(urlTrackId); // Utilizar el trackId de la URL si está presente
    } else {
      setTrackId(uuidv4()); // Generar un nuevo trackId si no está presente en la URL
    }
  }, [location.search]);


  const reproducirSonido = () => {
    if (soundRef.current) {
      soundRef.current.play();
    }
  };

  return (
    <div className="confirmar-pedido">
      {loading && <Loading text="creando pedido"/>}
      <h2 className="title">Confirma tu pedido</h2>
      <form
        className="payment-form-container payment-box"
        onSubmit={handleSubmit}
      >
        <div className="flex-order-2">
          <div className="shipping-details">
            {shippingInfo ? (
              <div className="flex">
                <div className="shipping-info">
                  <div className="shipping-info-item">
                    <span className="info-label">Nombre: </span>
                    <span className="info-value">
                      {shippingInfo.nombre} {shippingInfo.apellidos}
                    </span>
                  </div>
                  <div className="shipping-info-item">
                    <span className="info-label">Teléfono: </span>
                    <span className="info-value">{shippingInfo.telefono}</span>
                  </div>
                  <div className="shipping-info-item">
                    <span className="info-label">Correo: </span>
                    <span className="info-value">{shippingInfo.email}</span>
                  </div>
                </div>

                <Details />
              </div>
            ) : (
              <>
                <p>Los datos de envío no están disponibles.</p>
                <Link to="/menu">Seguir comprando</Link>
              </>
            )}
            <div className="flex-order-1">
              <p className="aviso-importante">
                Al confirmar tu pedido, envíanos una captura del yape/plin vía
                WhatsApp. ¡Gracias por tu preferencia!
              </p>
              {errorMessage && (
                <div className="error-message">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="checkout-options">
                <Link to="/checkout/shipping" className="checkout-option">
                  Regresar
                </Link>
                <div className="checkout-option" onClick={() => toggleCart()}>
                  editar Carrito
                </div>
              </div>
              {carrito.length > 0 && (
                <button
                  type="submit"
                  className="submit-button"
                  onClick={reproducirSonido}
                >
                  <p>Confirmar</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CheckoutPayment;
